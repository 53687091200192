import React, { Fragment } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import useAuth from "context/auth";
import { LayoutLoginAndRegister, LayoutCMS, LayoutTesting } from "./Layout";
import { USER_ROLE } from "util/config";
import { message } from "antd";
import ErrorBoundary from "pages/ErrorBoundary/ErrorBoundary";
import AlertBar from "components/NavBar/AlertBar";
import VerifyEmail from "pages/VerifyEmail/VerifyEmail";
import ReactGA from "react-ga";
import { getCookie, setCookie } from "util/helper";
import { setLocalStorage } from "util/localstorage";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import ResetPassword from "pages/ForgotPassword/ResetPassword";
import Language from "pages/Profile/Language/Language";
import MyExamination from "pages/myExamination/myExamination";
import MyFavorite from "pages/myFavorite/myFavorite";

const Login = loadable(() => import("./pages/Login/Login"));
const Register = loadable(() => import("./pages/Register/Register"));
const Home = loadable(() => import("./pages/Home/Home"));
const Group = loadable(() => import("./pages/Group/Group"));
const GroupDetail = loadable(() => import("./pages/Group/GroupDetail"));
const SuggestExam = loadable(() => import("./pages/Exam/Suggest/SuggestExam"));
const Bundle = loadable(() =>
  import("./pages/Home/Components/BundleCard/Bundle")
);
const BundlePage = loadable(() =>
  import("./pages/Home/Components/BundleCard/BundlePage")
);
const ReportGroup = loadable(() => import("./pages/Group/ReportGroup"));
const HistoryInGroup = loadable(() =>
  import("./pages/Group/History/HistoryInGroup")
);
const GroupSearch = loadable(() =>
  import("./pages/Group/GroupSearch/GroupSearch")
);

//! Subject Part
const Subject = loadable(() => import("./pages/Exam/Subject/Subject"));
const Publisher = loadable(() => import("./pages/publisher/Publisher"));
const PublisherSubject = loadable(() =>
  import("./pages/publisher/PublisherSubject")
);

// import ExamSearch from './pages/Exam/ExamSearch/ExamSearch'
const Bookmark = loadable(() => import("./pages/Bookmark/Bookmark"));
const Free = loadable(() => import("./pages/Free/Free"));
const MyExam = loadable(() => import("./pages/MyExam/MyExam"));
const Profile = loadable(() => import("pages/Profile/Profile"));
const EditProfile = loadable(() =>
  import("pages/Profile/EditProfile/EditProfile")
);
const Payment = loadable(() => import("./pages/Payment/Payment"));
const AddCard = loadable(() => import("./pages/Profile/Store/AddCard/AddCard"));
const CradDetail = loadable(() =>
  import("./pages/Profile/AccountSecurity/CradDetail/CradDetail")
);
const AccountSecurity = loadable(() =>
  import("pages/Profile/AccountSecurity/AccountSecurity")
);
const Testing = loadable(() => import("./pages/Testing"));
const Answer = loadable(() => import("./pages/Answer/Answer"));
const Result = loadable(() => import("./pages/Testing/Result/Result"));
// const PurchaseHistory = loadable(() => import('pages/Profile/PurchaseHistory/PurchaseHistory'))
const TestingHistory = loadable(() =>
  import("pages/Profile/TestingHistory/TestingHistory")
);
// const TestingHistoryList = loadable(() => import('pages/Profile/TestingHistory/TestingHistoryList/TestingHistoryList'))
const TestingHistoryDetail = loadable(() =>
  import(
    "pages/Profile/TestingHistory/TestingHistoryDetail/TestingHistoryDetail"
  )
);
const StatTesting = loadable(() =>
  import("pages/Profile/StatTesting/StatTesting")
);
const StatTestingDetail = loadable(() =>
  import("pages/Profile/StatTesting/StatTestingDetail/StatTestingDetail")
);
const PlanAndPricing = loadable(() =>
  import("./pages/CardPlanAndPricing/PlanAndPricing")
);
const TermAndConditions = loadable(() =>
  import("pages/TermAndConditions/TermAndConditions")
);
const PrivacyPolicy = loadable(() =>
  import("pages/PrivacyPolicy/PrivacyPolicy")
);
const ContactUs = loadable(() => import("../src/pages/Contact/ContactUs"));
const Pretest = loadable(() => import("pages/Pretest/Pretest"));
const PretestDetail = loadable(() =>
  import("pages/Pretest/PretestDetail/PretestDetail")
);
const HistoryPretest = loadable(() =>
  import("./pages/Pretest/History/HistoryPretest")
);
const ResultPretest = loadable(() =>
  import("./pages/Pretest/History/ResultPretest/ResultPretest")
);

const ManagePackages = loadable(() =>
  import("./pages/Profile/Store/ManagePackages/ManagePackages")
);
const Package = loadable(() => import("./pages/Package/Package"));
const Category = loadable(() => import("./pages/Package/Category"));
const Purchase = loadable(() => import("./pages/Purchase/Purchase"));
const PurchaseComplete = loadable(() =>
  import("./pages/Purchase/PurchaseComplete")
);
const PackageProfile = loadable(() =>
  import("./pages/Profile/Package/Package")
);
const AddATMCard = loadable(() => import("./pages/AddATMCard/AddATMCard"));
const SelectCard = loadable(() =>
  import("./pages/Profile/Store/ManagePackages/SelectCard/SelectCard")
);
const GroupStudentAnalysis = loadable(() =>
  import("./pages/Group/Member/GroupStudentAnalysis/GroupStudentAnalysis")
);
const GroupTestingHistory = loadable(() =>
  import("./pages/Group/Member/GroupTestingHistory/GroupTestingHistory")
);
// const Landing = loadable(() => import('./pages/Landing/Landing'))
const MyexamReport = loadable(() =>
  import("./pages/MyExam/MyExamReport/MyExamReport")
);
const FilePDF = loadable(() => import("pages/File/FilePDF"));
const ReportExamPDF = loadable(() =>
  import("pages/Group/ReportPDF/ReportExamPDF")
);
const ReportMemberPDF = loadable(() =>
  import("pages/Group/ReportPDF/ReportMemberPDF")
);
const PurchaseHistory = loadable(() =>
  import("./pages/Profile/PurchaseHistory/PurchaseHistory")
);

const { TEACHER, STUDENT, GUEST } = USER_ROLE;
const GatewayRoute = ({
  component: Component,
  layout: Layout,
  allowRole,
  path,
  exact,
  activeMenu,
  displayName,
}) => {
  const {
    state: { user, isAuthenticated, isLoading },
  } = useAuth();

  if ("location" in window) {
    ReactGA.ga("send", "pageview", window.location.pathname);
  }

  return (
    <Route
      exact={!!exact}
      path={path}
      render={(props) => {
        Component.displayName = displayName;
        const pageInfo = { pageName: displayName, userdetail: user };
        if (isLoading) {
          return <div></div>;
        }
        if (isAuthenticated) {
          const controller = new AbortController();
          controller.abort();
          return (
            <Fragment>
              <AlertBar {...props} pageInfo={pageInfo} />
              {allowRole.includes(user.role) ? (
                <Layout activeMenu={activeMenu}>
                  <Component
                    component
                    isAuthenticated
                    {...props}
                    pageInfo={pageInfo}
                  />
                </Layout>
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )}
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <AlertBar {...props} pageInfo={pageInfo} />
              {allowRole.includes(GUEST) ? (
                <Layout activeMenu={activeMenu}>
                  <Component component isAuthenticated {...props} />
                </Layout>
              ) : (
                <Redirect to={{ pathname: "/login" }} />
              )}
            </Fragment>
          );
        }
      }}
    />
  );
};

const AppRouter = () => {
  setLocalStorage("_lang", "th");
  if (window.navigator?.onLine) {
    window.addEventListener("offline", (e) => {
      if (localStorage.getItem("internetStatus") !== "offLine") {
        setCookie("getOff", "true", 0.2);
        if (getCookie("getOff") !== "true") {
          message.error("No Internet Connection", [5]);
        }
        localStorage.setItem("internet", "disconnected");
        localStorage.setItem("internetStatus", "offLine");
      }
    });
    window.addEventListener("online", (e) => {
      if (localStorage.getItem("internetStatus") !== "online") {
        message.info("Internet connected", [5]);
        localStorage.removeItem("internet");
        localStorage.setItem("internetStatus", "online");
      }
    });
  }

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Switch>
          <GatewayRoute
            exact
            path="/"
            displayName={"Home"}
            component={Home}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER, GUEST]}
            activeMenu={"exam"}
          />
          <GatewayRoute
            exact
            path="/login"
            displayName={"Login"}
            component={Login}
            layout={LayoutLoginAndRegister}
            allowRole={[GUEST]}
          />
          <GatewayRoute
            exact
            path="/register"
            displayName={"Register"}
            component={Register}
            layout={LayoutLoginAndRegister}
            allowRole={[GUEST]}
          />
          <GatewayRoute
            exact
            path="/forgotpassword"
            displayName={"ForgotPassword"}
            component={ForgotPassword}
            layout={LayoutLoginAndRegister}
            allowRole={[GUEST]}
          />
          <GatewayRoute
            exact
            path="/forgotpassword/verify"
            displayName={"ResetPassword"}
            component={ResetPassword}
            layout={LayoutLoginAndRegister}
            allowRole={[GUEST]}
          />
          <GatewayRoute
            exact
            path="/payment"
            displayName={"Payment"}
            component={Payment}
            layout={LayoutLoginAndRegister}
            allowRole={[GUEST, STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile"
            displayName={"Profile"}
            component={Profile}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/edit"
            displayName={"EditProfile"}
            component={EditProfile}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/language"
            displayName={"Language"}
            component={Language}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/security"
            displayName={"AccountSecurity"}
            component={AccountSecurity}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/purchase-history"
            displayName={"PurchaseHistory"}
            component={PurchaseHistory}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/security/add-card"
            displayName={"AddCard"}
            component={AddCard}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/security/card-detail"
            displayName={"CradDetail"}
            component={CradDetail}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />

          <GatewayRoute
            exact
            path="/profile/testing-history"
            displayName={"TestingHistory"}
            component={TestingHistory}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          {/* <GatewayRoute exact path='/profile/testing-history/detail' displayName={'TestingHistoryDetail'} component={TestingHistoryDetail} layout={LayoutCMS} allowRole={[STUDENT, TEACHER]} /> */}
          {/* <GatewayRoute exact path='/profile/store' displayName={'StorePage'} component={ StorePage } layout={  LayoutCMS } allowRole={[STUDENT, TEACHER]} />  */}
          <GatewayRoute
            exact
            path="/profile/store/manage/package"
            displayName={"ManagePackages"}
            component={ManagePackages}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/select-card"
            displayName={"SelectCard"}
            component={SelectCard}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/stat-testing"
            displayName={"StatTesting"}
            component={StatTesting}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/stat-testing/detail"
            displayName={"StatTestingDetail"}
            component={StatTestingDetail}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/profile/package"
            displayName={"PackageProfile"}
            component={PackageProfile}
            layout={LayoutCMS}
            allowRole={[STUDENT]}
          />
          {/* <GatewayRoute exact path='/' displayName={'Home'} component={Home} layout={LayoutCMS} allowRole={[STUDENT, TEACHER, GUEST]} activeMenu={'exam'} />
          <GatewayRoute exact path='/' displayName={'Home'} component={Home} layout={LayoutCMS} allowRole={[STUDENT, TEACHER, GUEST]} activeMenu={'exam'} /> */}
          <GatewayRoute
            exact
            path="/home/suggest"
            displayName={"SuggestExam"}
            component={SuggestExam}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"exam"}
          />
          <GatewayRoute
            exact
            path="/bundle"
            displayName={"Bundle"}
            component={Bundle}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER, GUEST]}
            activeMenu={"exam"}
          />
          <GatewayRoute
            exact
            path="/bundle/:id"
            displayName={"BundlePage"}
            component={BundlePage}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER, GUEST]}
            activeMenu={"exam"}
          />
          <GatewayRoute
            exact
            path="/my-exam"
            displayName={"MyExam"}
            component={MyExam}
            layout={LayoutCMS}
            allowRole={[TEACHER]}
            activeMenu={"my-exam"}
          />
          <GatewayRoute
            exact
            path="/my-exam/report"
            displayName={"MyexamReport"}
            component={MyexamReport}
            layout={LayoutCMS}
            allowRole={[TEACHER]}
            activeMenu={"my-exam"}
          />
          <GatewayRoute
            exact
            path="/group"
            displayName={"Group"}
            component={Group}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"group"}
          />
          <GatewayRoute
            exact
            path="/group/:id/detail"
            displayName={"GroupDetail"}
            component={GroupDetail}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"group"}
          />
          <GatewayRoute
            exact
            path="/group/:id/exam/:id/report"
            displayName={"ReportGroup"}
            component={ReportGroup}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"group"}
          />
          <GatewayRoute
            exact
            path="/group/:id/exam/:id/history"
            displayName={"HistoryInGroup"}
            component={HistoryInGroup}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"group"}
          />
          <GatewayRoute
            exact
            path="/group/:id/student/:id/history"
            displayName={"GroupTestingHistory"}
            component={GroupTestingHistory}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"group"}
          />
          <GatewayRoute
            exact
            path="/group/:id/student/:id/analysis"
            displayName={"GroupStudentAnalysis"}
            component={GroupStudentAnalysis}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"group"}
          />
          <GatewayRoute
            exact
            path="/group/search"
            displayName={"GroupSearch"}
            component={GroupSearch}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"group"}
          />
          <GatewayRoute
            exact
            path="/subject/:id"
            displayName={"Subject"}
            component={Subject}
            layout={LayoutCMS}
            allowRole={[GUEST, STUDENT, TEACHER]}
            activeMenu={"exam"}
          />
          <GatewayRoute
            exact
            path="/publisher/:id"
            displayName={"Publisher"}
            component={Publisher}
            layout={LayoutCMS}
            allowRole={[GUEST, STUDENT, TEACHER]}
            activeMenu={"exam"}
          />
          <GatewayRoute
            exact
            path="/publisher/:id/:id"
            displayName={"Publisher"}
            component={PublisherSubject}
            layout={LayoutCMS}
            allowRole={[GUEST, STUDENT, TEACHER]}
            activeMenu={"exam"}
          />
          {/* <GatewayRoute exact path='/bookmark' displayName={'Bookmark'} component={Bookmark} layout={LayoutCMS} allowRole={[GUEST, STUDENT, TEACHER]} activeMenu={'bookmark'} /> */}
          <GatewayRoute
            exact
            path="/free"
            displayName={"Free"}
            component={Free}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/testing"
            displayName={"Testing"}
            component={Testing}
            layout={LayoutTesting}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/testing/detail"
            displayName={"Result"}
            component={Result}
            layout={LayoutTesting}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/testing/:id"
            displayName={"Testing"}
            component={Testing}
            layout={LayoutTesting}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/testing/:id/result"
            displayName={"Result"}
            component={Result}
            layout={LayoutTesting}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/answer"
            displayName={"Answer"}
            component={Answer}
            layout={LayoutTesting}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/term-condition"
            displayName={"TermAndConditions"}
            component={TermAndConditions}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER, GUEST]}
          />
          <GatewayRoute
            exact
            path="/privacy-policy"
            displayName={"PrivacyPolicy"}
            component={PrivacyPolicy}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER, GUEST]}
          />
          <GatewayRoute
            exact
            path="/contact"
            displayName={"ContactUs"}
            component={ContactUs}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER, GUEST]}
          />
          <GatewayRoute
            exact
            path="/plan-pricing"
            displayName={"PlanAndPricing"}
            component={PlanAndPricing}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER, GUEST]}
          />
          <GatewayRoute
            exact
            path="/pretest"
            displayName={"Pretest"}
            component={Pretest}
            layout={LayoutCMS}
            allowRole={[STUDENT]}
            activeMenu={"pretest"}
          />
          <GatewayRoute
            exact
            path="/pretest/:id"
            displayName={"PretestDetail"}
            component={PretestDetail}
            layout={LayoutCMS}
            allowRole={[STUDENT]}
            activeMenu={"pretest"}
          />
          <GatewayRoute
            exact
            path="/pretest/:id/history"
            displayName={"HistoryPretest"}
            component={HistoryPretest}
            layout={LayoutCMS}
            allowRole={[STUDENT]}
            activeMenu={"pretest"}
          />
          <GatewayRoute
            exact
            path="/pretest/:id/result/:id"
            displayName={"ResultPretest"}
            component={ResultPretest}
            layout={LayoutTesting}
            allowRole={[STUDENT]}
            activeMenu={"pretest"}
          />
          <GatewayRoute
            exact
            path="/package"
            displayName={"Package"}
            component={Package}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"package"}
          />
          <GatewayRoute
            exact
            path="/package/category"
            displayName={"Category"}
            component={Category}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"package"}
          />
          <GatewayRoute
            exact
            path="/package/purchase"
            displayName={"Purchase"}
            component={Purchase}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
            activeMenu={"package"}
          />
          <GatewayRoute
            exact
            path="/add-atm-card"
            displayName={"AddATMCard"}
            component={AddATMCard}
            layout={LayoutLoginAndRegister}
            allowRole={[STUDENT, TEACHER]}
          />

          <GatewayRoute
            exact
            path="/myFavorite"
            displayName={"myFavorite"}
            component={MyFavorite}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/myExam"
            displayName={"myExam"}
            component={MyExamination}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER]}
          />
          <GatewayRoute
            exact
            path="/purchase"
            displayName={"Purchase"}
            component={Purchase}
            layout={LayoutCMS}
            allowRole={[STUDENT, TEACHER, GUEST]}
            activeMenu={"exam"}
          />
          <GatewayRoute
            exact
            path="/purchase/complete"
            displayName={"Purchase"}
            component={PurchaseComplete}
            layout={LayoutCMS}
            allowRole={[STUDENT]}
          />

          <Route
            exact={true}
            path={"/group/download/file/pdf"}
            displayName={"FilePDF"}
            component={FilePDF}
          ></Route>
          <Route
            exact={true}
            path={"/group/:groupId/report/exam/pdf"}
            displayName={"ReportExamPDF"}
            component={ReportExamPDF}
          ></Route>
          <Route
            exact={true}
            path={"/group/:groupId/report/member/pdf"}
            displayName={"ReportMemberPDF"}
            component={ReportMemberPDF}
          ></Route>
          {/* <GatewayRoute exact path='/landing' displayName={'Landing'} component={Landing} layout={LayoutCMS} allowRole={[GUEST, STUDENT, TEACHER]} /> */}
          {/* <GatewayRoute exact path='/profile/purchase-history' displayName={'PurchaseHistory'} component={PurchaseHistory} layout={LayoutCMS} allowRole={[STUDENT, TEACHER]} /> */}
          {/* <GatewayRoute exact path='/profile/testing-history/list' displayName={'TestingHistoryList'} component={TestingHistoryList} layout={LayoutCMS} allowRole={[STUDENT, TEACHER]} /> */}
          {/*<GatewayRoute exact path='/lesson/:id/video' displayName={'Video'} component={Video} layout={LayoutCMS}  allowRole={[STUDENT, TEACHER]} />
        <GatewayRoute exact path='/lesson/:id/video/:id' displayName={'VideoDetail'} component={VideoDetail} layout={LayoutCMS}  allowRole={[STUDENT, TEACHER]} />*/}
          <Route
            exact={true}
            path={"/verifyemail"}
            displayName={"VerifyEmail"}
            component={VerifyEmail}
          ></Route>
          <Route
            exact={true}
            path={"/verifyemail/th"}
            displayName={"VerifyEmail"}
            component={VerifyEmail}
          ></Route>
          <Route
            exact={true}
            path={"/verifyemail/en"}
            displayName={"VerifyEmail"}
            component={VerifyEmail}
          ></Route>

          <Route
            exact={true}
            path={"/verifyemail?result=false"}
            displayName={"VerifyEmail"}
            component={VerifyEmail}
          ></Route>
          <Route
            exact={true}
            path={"/verifyemail/th?result=false"}
            displayName={"VerifyEmail"}
            component={VerifyEmail}
          ></Route>
          <Route
            exact={true}
            path={"/verifyemail/en?result=false"}
            displayName={"VerifyEmail"}
            component={VerifyEmail}
          ></Route>

          {/* AddATMCard */}
          <Route
            exact
            path="/apple-app-site-association"
            render={() => {
              return (
                <pre>
                  {JSON.stringify({
                    applinks: {
                      apps: [],
                      details: [
                        {
                          appIDs: ["48PB24XLFG.com.sobtid.primaryNHigh"],
                          components: [
                            {
                              "/": "/*",
                              comment: "Group",
                            },
                          ],
                        },
                        {
                          appID: "48PB24XLFG.com.sobtid.primaryNHigh",
                          paths: ["/*"],
                        },
                      ],
                    },
                  })}
                </pre>
              );
            }}
          />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default AppRouter;
