export const LOCAL_STORAGE_KEYS = {
  Identifier: "_ident",
  OTP: "PS_TPLE",
  TESTING: "exper",
  FirebaseToken: "_fsbtk",
};

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || "";

export const USER_ROLE = {
  STUDENT: "Student",
  TEACHER: "Teacher",
  GUEST: "GUEST",
};

export const PAYMENT_STATUS = {
  actived: {
    text: "ชำระแล้ว",
    color: "#14B999",
  },
  pending: {
    text: "ยังไม่ได้ชำระ",
    color: "#F54B5E",
  },
  refund: {
    text: "คืนเงินแล้ว",
    color: "#C2C2C2",
  },
};

export const PAYMENT_TYPE = {
  free: "Free",
  creditCard: "Credit Card",
  qrCode: "QR Code",
};

export const GradeMapText = (grade) => `ประถม ${grade}`;

export const IMAGE_TYPE = ["image/jpeg", "image/png", "image/gif"];

export const chartTheme = [
  "#EE5294",
  "#9B58B5",
  "#258C51",
  "#F7941D",
  "#FB2B42",
  "#1DA9F7",
  "#7E8C8D",
  "#18C562",
  "#1D5AF7",
  "#F1C40F",
  "#BEC3C7",
  "#16A086",
  "#D25400",
  "#741FE0",
  "#FF6C6C",
];

export const GRADES = [
  {
    text: "ประถม 1",
    key: 1,
  },
  {
    text: "ประถม 2",
    key: 2,
  },
  {
    text: "ประถม 3",
    key: 3,
  },
  {
    text: "ประถม 4",
    key: 4,
  },
  {
    text: "ประถม 5",
    key: 5,
  },
  {
    text: "ประถม 6",
    key: 6,
  },
  {
    text: "มัธยม 1",
    key: 7,
  },
  {
    text: "มัธยม 2",
    key: 8,
  },
  {
    text: "มัธยม 3",
    key: 9,
  },
  {
    text: "มัธยม 4",
    key: 10,
  },
  {
    text: "มัธยม 5",
    key: 11,
  },
  {
    text: "มัธยม 6",
    key: 12,
  },
];

export const Publisher = [
  {
    name: "สถาบันภาษา จฬ.",
    key: "CU-PUB",
  },
  {
    name: "สำนักพิมพ์ ภูมิบัณฑิต",
    key: "PBD",
  },
  {
    name: "สำนักพิมพ์ SE-ED",
    key: "SE-ED",
  },
];

export const ThaiMemberRole = {
  Owner: "เจ้าของกลุ่ม",
  TeacherAssistant: "ครู/อาจารย์",
  Student: "นักเรียน",
};

// export const EducationLevel = [
//   {
//     id: 'ElementarySchool',
//     name: 'ระดับประถม'
//   },
//   {
//     id: 'JuniorHighSchool',
//     name: 'ระดับมัธยมต้น'
//   }
// ]

export const EducationLevel = [
  {
    id: "ElementarySchool",
    name: "ระดับประถม",
  },
  {
    id: "JuniorHighSchool",
    name: "ระดับมัธยมต้น",
  },
  {
    id: "PreUniversity",
    name: "เตรียมสอบระดับอุดมศึกษา",
  },
  {
    id: "PreEnglishProficiency",
    name: "เตรียมสอบวัดระดับทางภาษา",
  },
];

export const SubjectGroupName = [
  {
    id: "lower_hight_school",
    name: "ระดับมัธยมต้น",
  },
  {
    id: "uppper_hight_school",
    name: "ระดับมัธยมปลาย",
  },
  {
    id: "a_level",
    name: "A-Level",
  },
  {
    id: "gat_pat",
    name: "GAT - PAT",
  },
  {
    id: "pre_m4",
    name: "เตรียมสอบเข้าม.4",
  },
  {
    id: "posn",
    name: "สอวน.",
  },
];

export const EducationTabPretest = {
  ElementarySchool: [
    {
      name: "เตรียมสอบเข้า ป.1",
      key: "grade1",
    },
    {
      name: "เตรียมสอบเข้า ป.4",
      key: "grade4",
    },
  ],
  JuniorHighSchool: [
    {
      name: "เตรียมสอบเข้า ม.1",
      key: "grade7",
    },
    {
      name: "เตรียมสอบเข้า ม.4",
      key: "grade9",
    },
  ],
  BachlorDegree: [
    {
      name: "GAT",
      key: "GAT",
    },
    {
      name: "PAT",
      key: "PAT",
    },
  ],
  LanguegeTest: [
    {
      name: "CU-TEP",
      key: "CU-TEP",
    },
    {
      name: "TU-GET",
      key: "TU_GET",
    },
    {
      name: "CU-TEP + TU-GET",
      key: "CUTU",
    },
  ],
};

export const MarketLevel = [
  {
    id: "Package",
    name: "แพ็คเกจ",
  },
  {
    id: "Publisher",
    name: "สำนักพิมพ์",
  },
];

export const PackageCardData = [
  {
    packageClass: "ระดับชั้นประถม",
    cost: 159,
    examItem: 12345,
    examDuration: 365,
  },
  {
    packageClass: "ระดับชั้นมัธยม",
    cost: 259,
    examItem: 12345,
    examDuration: 365,
  },
  {
    packageClass: "เตรียมสอบเข้าระดับอุดมศึกษา",
    cost: 359,
    examItem: 12345,
    examDuration: 365,
  },
  {
    packageClass: "เตรียมสอบวัดระดับทางภาษา",
    cost: 459,
    examItem: 12345,
    examDuration: 365,
  },
];

export const PublisherCardData = [
  {
    publisherName: "Publisher Name1",
    cost: 159,
    examItem: 1234,
    examDuration: 365,
  },
  {
    publisherName: "Publisher Name2",
    cost: 159,
    examItem: 1234,
    examDuration: 365,
  },
  {
    publisherName: "Publisher Name3",
    cost: 159,
    examItem: 1234,
    examDuration: 365,
  },
  {
    publisherName: "Publisher Name4",
    cost: 159,
    examItem: 1234,
    examDuration: 365,
  },
  {
    publisherName: "Publisher Name5",
    cost: 159,
    examItem: 1234,
    examDuration: 365,
  },
];

export const PDF_FONT = "THSarabun";
export const PDF_BASE_MARGIN_TOP = 35;
export const PDF_BASE_MARGIN_LEFT = 30;
export const PDF_TABLE_FULL_WIDTH = 530;

export const PDF_THAI_COLOR_RGB = [159, 36, 237];
export const PDF_ENG_COLOR_RGB = [239, 37, 179];
export const PDF_SOC_COLOR_RGB = [47, 163, 63];
export const PDF_SCI_COLOR_RGB = [57, 25, 243];
export const PDF_MATH_COLOR_RGB = [255, 136, 37];
export const PDF_MATH2_COLOR_RGB = [255, 108, 62];

export const HEADER_COLUMN_GAP = 12;
// export const PDF_THAI_COLOR_RGB = [57, 25, 243]

export const STYLE_TABLE = {
  HEAD: {
    fontStyle: "bold",
    font: "THSarabun",
    fontSize: 14,
    fillColor: "#eaeaea",
    textColor: "black",
    lineColor: "black",
    valign: "middle",
  },
  BODY: {
    font: "THSarabun",
    fontStyle: "normal",
    fontSize: 14,
  },
  BODY_ALL_CORRECT: {
    font: "THSarabun",
    fontStyle: "normal",
    textColor: "#15cf62",
    fontSize: 14,
  },
  maxCellHeight: 4,
};

export const TAB_SUBJECT = {
  primaryId: ["THAI", "ENG", "SCI", "MATH", "SOC", ""],
  hightId: [
    "THAI",
    "ENG",
    "SCI",
    "SOC",
    "c6e35b15-53ed-436a-941d-56072f4a2043",
    "e7b12659-abc1-4658-8ac9-0650ccaf3ee6",
    "",
  ],
  lastId: [
    "THAI",
    "SCI",
    "SOC",
    "c6e35b15-53ed-436a-941d-56072f4a2043",
    "e7b12659-abc1-4658-8ac9-0650ccaf3ee6",
    "",
  ],
};

export const FILTER_GRADE = {
  PAGE_NAME: {
    Subject: "Subject",
    Bookmark: "Bookmark",
    Free: "Free",
    MyExam: "MyExam",
    SuggestExam: "SuggestExam",
    TestingHistory: "TestingHistory",
    StatTesting: "StatTesting",
    GroupTestingHistory: "GroupTestingHistory",
    GroupStudentAnalysis: "GroupStudentAnalysis",
  },
};

export const pastelCode = [
  "#F16DA1",
  "#C9447E",
  "#F37FA9",
  "#A82558",
  "#F691B2",
  "#901B48",
  "#F8A3BB",
  "#78163D",
  "#FAB5C3",
  "#601230",
  "#FBC7CC",
  "#480C25",
  "#FFD9FF",
  "#FEC7F7",
  "#FFEBDE",
];

export const PRIMARY_COLOR = "#0baf50"; // pink '#EE5294' // green '#0baf50'
export const SECONDARY_COLOR = "#3ac95d"; // pink '#D6005F'// green '#3ac95d'
export const DISABLE_COLOR = "#afafaf";
export const VERY_LIGHTPINK_COLOR = "#d7d7d7";
export const BROWNISH_GREY_COLOR = "#737373";
export const ICE_COLOR = "#e9f8ef"; // pink '#fde4f2' // green '#e9f8ef'
export const BLACK_COLOR = "#383838";
export const LIGHT_BLUE_GREY = "#d0d2d3";
export const COOL_GREY_COLOR = "#a6a8ab";
export const SLATE_GREY_COLOR = "#6d6e70";
export const WRONG_COLOR = "#f54b5e";
export const WARNING_COLOR = "#f4a802";
export const LINE_COLOR = "#eaeaea";
export const BORDER_LINE = "#3ad047"; // pink #EE5294 // green '#31af50' //card exam  greencolor = #3ad047
export const CORRECT_COLOR = "#0baf50";
// export const SHADOW_CORRECT_COLOR = '#0baf50'

//CU
export const NETURAL_0 = "#FFFFFF";
export const NETURAL_200 = "#EBEBEB";
export const NETURAL_400 = "#C2C2C2";

export const PRIMARY_200_CU_COLOR = "#FFEEF5";
export const PRIMARY_700_CU_COLOR = "#EE5294";
export const PRIMARY_800_CU_COLOR = "#C53974";

export const SECONDARY_1000_COLOR = "#0A0D14";

export const ITEM_DISABLE = "#C2C2C2";
export const ITEM_PRIMARY = "#0A0D14";
export const ITEM_SECONDARY = "#999899";
export const ITEM_WHITE = "#FFFFFF";

// color code #db3581 #db3581 #db3581 #db3581 #db3581
