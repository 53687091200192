import { updateFirebaseToken } from "api";

let isUpdatingToken = false;

export const throttleUpdateFirebaseToken = async (action) => {
  if (isUpdatingToken) {
    console.log("🚀Update token throttled");
    return;
  }
  isUpdatingToken = true;

  console.log("Updating Firebase token...");
  await updateFirebaseToken(action);

  console.log("🚀Firebase token updated");
  isUpdatingToken = false;
};
