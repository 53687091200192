import React, { useEffect, useState } from 'react'
import { Button, Input, Form, message, Spin } from 'antd'
import IconPassword from "assets/icons/icon_password_kengsob.svg";
import IconUnsuccess from "assets/icons/icon-unsuccess.svg";
import styles from './ResetPassword.module.scss'
import { verifyResetPasswordTokenService, resetPassword } from 'api/auth'
// import ConfirmStep from './components/ConfirmStep'
import { TranslateLanguage } from 'util/Translate'
import { vocabResetPassword } from 'util/Translate.variable'
import ModalSuccess from 'components/Modal/ModalSuccess'
import { getUrlQueryString } from 'util/helper';
import { LoadingOutlined } from '@ant-design/icons';

const translate = new TranslateLanguage()
translate.addMaping(vocabResetPassword)


function ResetPassword(props) {
    const [isCheckingToken, setIsCheckingToken] = useState(true)
    const [isTokenValid, setIsTokenValid] = useState(false)
    const [memberId, setMemberId] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [visibleSentVerifyModal, setVisibleSentVerifyModal] = useState(false)

    useEffect(() => {
        const { token } = getUrlQueryString(props.location.search)
        if (!token) {
            setIsCheckingToken(false)
            setIsTokenValid(false)
            return;
        }
        verifyToken(token)
    }, [])

    const verifyToken = async token => {
        const response = await verifyResetPasswordTokenService(token)
        if (response && !response.error) {
            console.log(response)
            setMemberId(response.memberId)
            setIsTokenValid(true)
        } else {
            setIsTokenValid(false)
        }
        setIsCheckingToken(false)
    }
    const submitResetPassword = async values => {
        setIsLoading(true)
        const res = await resetPassword({ memberId, newPassword: values.password })
        if (res && !res.error) {
            setIsLoading(false)
            message.success(res.message)
            props.history.push('/login')
        } else {
            setIsLoading(false)
            message.error(res.error.message)
        }

    }

    return (
        <div className={styles.container}>
            {
                isCheckingToken ? <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} /> :
                    isTokenValid ?
                        <div>
                            <div>
                                <h2 className={styles.title}>{translate.convert('ตั้งรหัสผ่านใหม่')}</h2>
                            </div>
                            <div className={styles.divStep}>
                                <Form
                                    name='form_reset_password'
                                    className={styles.formResetPassword}
                                    onFinish={submitResetPassword}
                                >
                                    <Form.Item
                                        name='password'
                                        className={styles.inputBox}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate.convert('รหัสผ่านอย่างน้อย 8 ตัวอักษร'),
                                                min: 8
                                            },
                                        ]}
                                    >
                                        <Input.Password className='inputForm' prefix={<img alt="IconPassword" src={IconPassword} />} placeholder={translate.convert('รหัสผ่านอย่างน้อย 8 ตัวอักษร')} />
                                    </Form.Item>
                                    <Button type='primary' htmlType='submit' className={styles.btnLogin} loading={isLoading}>
                                        {translate.convert('ยืนยัน')}
                                    </Button>
                                </Form>
                            </div>
                        </div> :
                        <div className={styles.unsuccessTokenWrap}>
                            <div><img alt='IconUnsuccess' src={IconUnsuccess} /></div>
                            <h2 className={styles.title}>{translate.convert('คำร้องหมดอายุ')}</h2>
                            <p>{translate.convert('กรุณาส่งคำร้องใหม่อีกครั้ง และตรวจสอบอีเมล หรือ หมายเลขโทรศัพท์ของท่าน เพื่อทำการตั้งรหัสผ่านใหม่')}</p>
                            <Button type='primary' className={styles.btnLogin} onClick={() => props.history.push('/forgotpassword')}>
                                {translate.convert('ส่งคำร้องใหม่')}
                            </Button>
                        </div>
            }
            <ModalSuccess visible={visibleSentVerifyModal} onClose={() => setVisibleSentVerifyModal(false)} title={'ส่งคำร้องสำเร็จ'} content={'กรุณาตรวจสอบอีเมล หรือ หมายเลขโทรศัพท์ของท่าน และทำการเข้าสู่ระบบอีกครั้ง'} />
        </div>
    )
}

export default ResetPassword