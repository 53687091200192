import React, { useState, useRef, useEffect } from "react";
import BundleDefault from "assets/icons/A4.svg";
import {
  HeartOutlined,
  ShareAltOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Book from "assets/icons/book.svg";
import Item from "assets/icons/item.svg";
import Subject from "assets/icons/subject.svg";
import { Divider, Button, Rate } from "antd";
import ShareModal from "./BundleShare";
import styles from "./TableBundle.module.scss";
import { patchFavoriteBundle, patchUnfavoriteBundle } from "api/bundle";
import { useDataStore } from "store/DataStoreContext";
import _ from "lodash";

const TableBundle = ({ bundleItem, i, handleClickCard, refetch }) => {
  const {
    state: { allSubject },
  } = useDataStore();

  const [isLoading, setIsLoading] = useState(false);
  const [displayShareModal, setDisplayShareModal] = useState(false);
  const preventClickRef = useRef(false);

  const onClickLike = async (event) => {
    setIsLoading(true);
    event.stopPropagation();
    if (bundleItem.isFavorite) {
      await patchUnfavoriteBundle({ id: bundleItem.id });
    } else {
      await patchFavoriteBundle({ id: bundleItem.id });
    }
    refetch();
    setIsLoading(false);
  };

  const openShareModal = () => {
    setDisplayShareModal(true);
  };

  const closeShareModal = () => {
    setDisplayShareModal(false);
  };

  const onClickShare = (event) => {
    preventClickRef.current = true;
    event.stopPropagation();
    setDisplayShareModal((prev) => !prev);
  };

  const handleCardClick = () => {
    if (preventClickRef.current) {
      preventClickRef.current = false;
      return;
    }
    handleClickCard(bundleItem.id);
  };

  return (
    <div className={styles.main} onClick={handleCardClick}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.bundle_card_banner}>
            <div className={styles.bundle_banner}>
              {!bundleItem.banner ? (
                <img
                  src={bundleItem.cover}
                  alt={bundleItem.name}
                  className={styles.bundle_card_banner_img}
                />
              ) : (
                <img
                  src={BundleDefault}
                  alt="default"
                  className={styles.bundle_card_banner_img}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.name}>{bundleItem.name}</div>
          <div className={styles.publisher}>{bundleItem.publisher}</div>
          <div className={styles.review}>
            <div className={styles.amount}>
              {_.round(bundleItem.review.reviewPoint * 2) / 2}
            </div>
            <Rate
              defaultValue={bundleItem.review.reviewPoint}
              allowHalf
              disabled
            />
          </div>
          <div className={styles.detail}>
            <div className={styles.data_amount}>
              <img src={Book} alt="item" className={styles.icon} />
              <div className={styles.data}>{bundleItem.totalExam} ชุด</div>
            </div>
            <div
              className={styles.data_amount}
              style={{ margin: "0 2rem 0 2rem" }}
            >
              <img src={Item} alt="item" className={styles.icon} />
              <div className={styles.data}>{bundleItem.totalQuestion} ข้อ</div>
            </div>
            <div className={styles.data_amount}>
              <img src={Subject} alt="item" className={styles.icon} />
              <div className={styles.data}>
                {_.join(
                  _.map(bundleItem.subject, (subId) => {
                    const subject = _.find(allSubject, { id: subId });
                    return subject ? subject.name : "";
                  }),
                  ", "
                )}
              </div>
            </div>
          </div>
          <div className={styles.button_container}>
            <Button
              type="primary"
              className={styles.bundle_card_button_buy}
              onClick={() =>
                console.log(
                  "🚀 ~ file: BundleCard.js ~ line 27 ~ BundleCard ~ onClick ~ bundleItem",
                  bundleItem
                )
              }
            >
              {bundleItem.isPurchase
                ? "เริ่มทำข้อสอบ"
                : bundleItem.price > 0
                ? `฿ ${bundleItem.price}`
                : "ฟรี"}
            </Button>
            <Button
              className={styles.button}
              style={
                bundleItem.isFavorite
                  ? { backgroundColor: "#ee5294", color: "white" }
                  : {}
              }
              onClick={onClickLike}
            >
              {isLoading ? <LoadingOutlined /> : <HeartOutlined />}
            </Button>
            <Button className={styles.button} onClick={onClickShare}>
              <ShareAltOutlined />
            </Button>
          </div>
        </div>
      </div>
      <Divider style={{ margin: "0" }} />
      <ShareModal
        displayShareModal={displayShareModal}
        openShareModal={openShareModal}
        closeShareModal={closeShareModal}
        url={bundleItem.linkBundle}
      />
    </div>
  );
};

export default TableBundle;
