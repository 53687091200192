import { tab } from "@testing-library/user-event/dist/tab";
import _ from "lodash";

export const filterSuggestExam = (suggest, level, number = 3) => {
  const filterByGrade = _.get(
    _.find(suggest, (each) => each.education === level),
    "tabBelow"
  );
  const allExams = _.flatMap(filterByGrade, (each) => each.exams);
  const shuffledExams = _.shuffle(allExams);

  return _.take(shuffledExams, number);
};

export const filteredSuggestInSuggest = (suggest, level) => {
  const result = suggest;

  return result;
};

//! Old one
export const filterSubject = (subjects) => {
  const result = [];

  _.forEach(subjects, (subject) => {
    _.forEach(subject.level, (level) => {
      let levelEntry = _.find(result, { id: level.id });

      if (!levelEntry) {
        levelEntry = {
          id: level.id,
          subjectGroup: {},
          hasSubjectGroup: !_.isEmpty(level.subjectGroup),
        };
        result.push(levelEntry);
      }

      if (_.isEmpty(level.subjectGroup)) {
        if (!levelEntry.subjectGroup["all"]) {
          levelEntry.subjectGroup["all"] = [];
        }
        levelEntry.subjectGroup["all"].push({
          id: subject.id,
          name: subject.name,
          shortName: subject.shortName,
          squareBanner: subject.squareBanner,
          rectangleBanner: subject.rectangleBanner,
          bannerColorCode: subject.bannerColorCode,
          statusBarColor: subject.statusBarColor,
          tagColor: subject.tagColor,
          cover: subject.cover,
          info: subject.info,
        });
      } else {
        _.forEach(level.subjectGroup, (group, groupName) => {
          if (!levelEntry.subjectGroup[group]) {
            levelEntry.subjectGroup[group] = [];
          }
          levelEntry.subjectGroup[group].push({
            id: subject.id,
            name: subject.name,
            shortName: subject.shortName,
            squareBanner: subject.squareBanner,
            rectangleBanner: subject.rectangleBanner,
            bannerColorCode: subject.bannerColorCode,
            statusBarColor: subject.statusBarColor,
            tagColor: subject.tagColor,
            cover: subject.cover,
            info: subject.info,
          });
        });
      }
    });
  });

  return result;
};

export const filterBundleByFetch = (bundle, level) => {
  const result = _.filter(bundle, (obj) => obj.level === level);
  return result;
};

export const filterBundleLabelByFetch = (filteredBundle) =>
  _.chain(filteredBundle)
    .thru((bundle) => ({
      rating: _.chain(bundle).map("rating").uniq().sortBy().reverse().value(),
      level: _.chain(bundle).map("level").uniq().sortBy().value(),
      grade: _.chain(bundle).map("grade").uniq().sortBy().value(),
      subject: _.chain(bundle).map("subject").uniq().sortBy().value(),
    }))
    .value();

export const filterHarvest = ({ core, level }) => {
  const allLevel = _.map(core, (item) => ({
    id: item.id,
    name: item.name,
  }));

  const allGrade = _.get(
    _.find(core, (obj) => obj.id === level),
    "grades"
  );

  return {
    level: allLevel,
    grade: allGrade,
  };
};
